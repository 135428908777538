import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Settings } from './config/settings.service';
import { Translations } from './translations/translations.service';
import { APP_CONFIG } from './config/app-config';
import { CurrentUser } from '../auth/current-user';
import { AppearanceListenerService } from '../shared/appearance/appearance-listener.service';
import { ThemeService } from '@common/core/theme.service';
import { AppHttpClient } from 'common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
export function init_app(bootstrapper) {
    return () => bootstrapper.bootstrap();
}
export class Bootstrapper {
    constructor(injector) {
        this.injector = injector;
        this.http = this.injector.get(HttpClient);
        this.settings = this.injector.get(Settings);
        this.currentUser = this.injector.get(CurrentUser);
        this.i18n = this.injector.get(Translations);
        // merge all config provided by modules into single object
        this.injector.get(APP_CONFIG).forEach(providedConfig => {
            return this.settings.merge({ vebto: providedConfig });
        });
    }
    /**
     * Bootstrap application with data returned from server.
     */
    bootstrap(data) {
        if (!data)
            data = window['bootstrapData'];
        // if we have bootstrap data in global scope, pass
        // it to the app and return self resolving promise
        if (data) {
            this.handleData(data);
            return new Promise(resolve => resolve());
        }
        // fetch bootstrap data from backend and return promise that
        // resolves once request is complete and data is passed to the app
        return new Promise((resolve, reject) => {
            const original = encodeURIComponent(window.location.href);
            const url = this.settings.getBaseUrl() + 'secure/bootstrap-data?original_url=' + original;
            this.http.get(url).subscribe(response => {
                this.handleData(response['data']);
                resolve();
            }, error => {
                console.log('bootstrap error', error);
                reject();
            });
        });
    }
    /**
     * Handle specified bootstrap data.
     */
    handleData(encodedData) {
        // decode bootstrap data from server
        const data = JSON.parse(atob(encodedData));
        // set all settings returned from server
        this.settings.setMultiple(data.settings);
        // set translations
        if (data.i18n) {
            this.i18n.setLocalization(data.i18n);
        }
        this.currentUser.setHttpClient(this.injector.get(AppHttpClient));
        // set current user and default role for guests
        this.currentUser.init({
            guestsRole: data.guests_role,
            user: data.user,
        });
        this.data = data;
        const themes = this.injector.get(ThemeService);
        if (window['beThemes']) {
            // default theme will be set via PHP, make sure we don't double set it on production
            themes.selectedTheme$.next(window['beThemes'][window['beSelectedTheme']]);
            themes.registerThemes(window['beThemes']);
        }
        else {
            themes.registerThemes(this.data.themes);
        }
        // init appearance listener after default theme is set
        this.injector.get(AppearanceListenerService).init();
        return data;
    }
}
Bootstrapper.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Bootstrapper_Factory() { return new Bootstrapper(i0.ɵɵinject(i0.INJECTOR)); }, token: Bootstrapper, providedIn: "root" });
